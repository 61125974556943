.button-container {
  display: flex;
  width: 100%;
}

.button-left {
  margin-right: auto;
}

.button-center {
  margin-left: auto;
  margin-right: auto;
}

.button-right {
  margin-left: auto;
}
