@use "_compact-table";
@import '../../theme/palletes';

.table{
  position: relative;
  min-height: 200px;
  &-feed{
    position: absolute;
    top: 56px;
    left: 0;
    bottom: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 2;
  }

  &-feed-text{
    text-align: center;
    width: 100%;
    height: 100%;
    max-width: 360px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.mat-header-cell {
  background-color: map-get($primary-palette, 500) !important;
  border-top-left-radius: 7px;
  border-top-right-radius: 7px;
  border-right: 1px solid white;
  text-align: center !important;
  &, .mat-sort-header-arrow {
    color: $light-primary;
  }
}

.mat-cell {
  text-align: center !important;
}

.mat-cell a {
  color: $dark-primary;
}


mat-paginator{
  .mat-mdc-form-field {
    width: 84px !important;
  }
}