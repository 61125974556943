.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin-top: 30px;
}

h1 {
  font-weight: bold;
}

.card {
  width: 100%;
  height: 100%;
  perspective: 1000px;
  transition: transform 0.3s ease-in-out;
}

.card-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.card-rotate {
  transform-style: preserve-3d;
  transition: transform 0.5s;
  transform: rotateY(0deg);
  transform-origin: center;
}

.card-rotate.flip {
  transform: rotateY(180deg);
  transform-origin: center;
}

.unflip-content {
  transform: rotateY(180deg);
}






