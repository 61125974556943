@import '../../theme/palletes';

.mat-sidenav {
  background: map-get($primary-palette, 500) !important;
  width: 260px !important;
}

.sidebar {
  &-item-sep {
    margin: 0 0 1rem;
  }

  &-branding {
    height: 64px;
    padding: 0 16px;
    display: flex;
    align-items: center;

    &-logo {
      height: 36px;
      max-height: 56px;
      background-repeat: no-repeat;
      background-size: contain;
      object-fit: contain;
      width: auto;
      max-width: 200px;
      margin-right: 8px;
    }
  }

  &-section {
    padding: 0;
    margin: .5rem 0;
    color: $light-primary;

    &>h2 {
      padding: 16px 0 0 16px;
      display: block;
      font-size: 12px;
      margin: 0;
      line-height: 1;
    }

  }
}


sidebar-menu .mat-expansion-panel.sidebar-menu-expansion-panel {
  box-shadow: none;
  background: transparent;
  color: $light-primary;
  border-radius: 0;

  .mat-expansion-panel-body {
    padding: 0;
  }

  .mat-expansion-indicator::after {
    color: $light-primary;
  }

  .mat-expansion-panel-header {
    padding-left: 0;
  }
}

.sidebar-menu-item {

  &-btn-expansion,
  &-btn {
    position: relative;
    display: flex;
    align-items: center;
    height: 48px;
    text-decoration: none;
    color: $light-primary;
    padding: 0 16px 0 0;
    min-width: 120px;
    transition: background-color 0.3s;
    cursor: pointer;
  }

  mat-icon {
    opacity: 0.7;
    font-size: 16px;
    height: 48px;
    width: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 4px;
    transition: opacity 0.3s;
  }

  &-text {
    padding-left: 52px;

    mat-icon+& {
      padding-left: 0;
    }
  }
}

sidebar-menu .mat-expansion-panel.sidebar-menu-expansion-panel .mat-expansion-panel-header:hover:not([aria-disabled=true]),
sidebar-menu .mat-expansion-panel.mat-expanded .mat-expansion-panel-header,
sidebar-menu .sidebar-menu-item-btn:hover,
sidebar-menu .sidebar-menu-item-active {
  --mat-expansion-header-hover-state-layer-color: #{$dark-primary};
  background-color: map-get($primary-palette, 800);
}
