.chart {
  width: 100%;
  display: flex;
  flex-direction: column;
  height: 400px;

  &__canvas {
    flex: 1;
    display: block;
  }
}

.chart-icon-reseet {
  cursor: pointer;
  position: relative;
  width: 16px;
  background-color: inherit;
  color: #4c516d;
  border-radius: 50%;
  border-style:  none;
}