$dark-primary: #00241A;
$light-primary: #FFFFFF;

$forest-green: #00593F;
$grass-green: #71C08A;
$sand-yellow: #FCC154;
$sky-blue: #8AC6E2;
$sunset-red: #F4663B;
$canyon-brown: #85442C;
$ocean-blue: #21456D;

$primary-palette: (
  50: #E1EBE8,
  100: #B3CDC5,
  200: #81AC9F,
  300: #4E8A79,
  400: #28715C,
  500: $forest-green,
  600: #025039,
  700: #014731,
  800: #013D29,
  900: #012D1B,
  950: #011A13,
  contrast: (
    50: $dark-primary,
    100: $dark-primary,
    200: $dark-primary,
    300: $dark-primary,
    400: $dark-primary,
    500: $light-primary,
    600: $light-primary,
    700: $light-primary,
    800: $light-primary,
    900: $light-primary,
    950: $light-primary,
  )
);

$success-palette: (
  50: #EDFCF2,
  100: #D4FAE0,
  200: #A9F5C1,
  300: #6BED94,
  400: #38E06D,
  500: #02C740,
  600: #00A233,
  700: #008229,
  800: #006620,
  900: #00591C,
  950: #00240B,
  contrast: (
    50: $dark-primary,
    100: $dark-primary,
    200: $dark-primary,
    300: $dark-primary,
    400: $dark-primary,
    500: $light-primary,
    600: $light-primary,
    700: $light-primary,
    800: $light-primary,
    900: $light-primary,
    950: $light-primary,
  )
);

$caution-palette: (
  50: #FCF7ED,
  100: #FAEDD4,
  200: #F5DAA9,
  300: #EDBF6B,
  400: #E0A538,
  500: #C77F02,
  600: #A26400,
  700: #824800,
  800: #663500,
  900: #592A00,
  950: #241100,
  contrast: (
    50: $dark-primary,
    100: $dark-primary,
    200: $dark-primary,
    300: $dark-primary,
    400: $dark-primary,
    500: $light-primary,
    600: $light-primary,
    700: $light-primary,
    800: $light-primary,
    900: $light-primary,
    950: $light-primary,
  )
);

$negative-palette: (
  50: #FCEDED,
  100: #FAD4D5,
  200: #F5A9AA,
  300: #ED6B6D,
  400: #E0383B,
  500: #C70205,
  600: #A20003,
  700: #820002,
  800: #660002,
  900: #590001,
  950: #240001,
  contrast: (
    50: $dark-primary,
    100: $dark-primary,
    200: $dark-primary,
    300: $light-primary,
    400: $light-primary,
    500: $light-primary,
    600: $light-primary,
    700: $light-primary,
    800: $light-primary,
    900: $light-primary,
    950: $light-primary,
  )
);

$info-palette: (
  50: #EDF7FC,
  100: #D4EEFA,
  200: #A9DDF5,
  300: #6BC4ED,
  400: #38ABE0,
  500: #0289C7,
  600: #006FA2,
  700: #005982,
  800: #004666,
  900: #003852,
  950: #001924,
  contrast: (
    50: $dark-primary,
    100: $dark-primary,
    200: $dark-primary,
    300: $dark-primary,
    400: $dark-primary,
    500: $light-primary,
    600: $light-primary,
    700: $light-primary,
    800: $light-primary,
    900: $light-primary,
    950: $light-primary,
  )
);

$sand-yellow-palette: (
  50: #FFF8EA,
  100: #FEECCC,
  200: #FFE7BC,
  300: #FEE0AA,
  400: #FDD487,
  500: $sand-yellow,
  600: #F0A437,
  700: #CC7910,
  800: #995A09,
  900: #593405,
  contrast: (
    50: $dark-primary,
    100: $dark-primary,
    200: $dark-primary,
    300: $dark-primary,
    400: $dark-primary,
    500: $light-primary,
    600: $light-primary,
    700: $light-primary,
    800: $light-primary,
    900: $light-primary,
  )
);

$sunset-red-palette: (
  50: #FEEDE7,
  100: #FCD1C4,
  200: #FAB39D,
  300: #F79476,
  400: #F67D58,
  500: $sunset-red,
  600: #EA4B29,
  700: #CC2D11,
  800: #9F240E,
  900: #591408,
  contrast: (
    50: $dark-primary,
    100: $dark-primary,
    200: $dark-primary,
    300: $dark-primary,
    400: $dark-primary,
    500: $light-primary,
    600: $light-primary,
    700: $light-primary,
    800: $light-primary,
    900: $light-primary,
  )
);

$sky-blue-palette: (
  50: #F1F8FC,
  100: #DCEEF6,
  200: #C5E3F1,
  300: #ADD7EB,
  400: #9CCFE6,
  500: $sky-blue,
  600: #5FAED9,
  700: #2687BF,
  800: #1A5E85,
  900: #0F364D,
  contrast: (
    50: $dark-primary,
    100: $dark-primary,
    200: $dark-primary,
    300: $dark-primary,
    400: $dark-primary,
    500: $light-primary,
    600: $light-primary,
    700: $light-primary,
    800: $light-primary,
    900: $light-primary,
  )
);

$grass-green-palette: (
  50: #EEF7F1,
  100: #D4ECDC,
  200: #B8E0C5,
  300: #9CD3AD,
  400: #86C99C,
  500: $grass-green,
  600: #54AA6D,
  700: #3B8C51,
  800: #1D6731,
  900: #134220,
  contrast: (
    50: $dark-primary,
    100: $dark-primary,
    200: $dark-primary,
    300: $dark-primary,
    400: $dark-primary,
    500: $light-primary,
    600: $light-primary,
    700: $light-primary,
    800: $light-primary,
    900: $light-primary,
  )
);

$neutral-palette: (
  50: #FAFAFA,
  100: #F4F4F5,
  200: #E4E5E7,
  300: #D4D6D8,
  400: #A1A5AA,
  500: #71757A,
  600: #52565B,
  700: #3F4246,
  800: #27282A,
  900: #18191B,
  contrast: (
    50: $dark-primary,
    100: $dark-primary,
    200: $dark-primary,
    300: $dark-primary,
    400: $dark-primary,
    500: $light-primary,
    600: $light-primary,
    700: $light-primary,
    800: $light-primary,
    900: $light-primary,
  )
);