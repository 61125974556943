.footer {
  width: 100%;
  font-size: 12px;

  &-inner {
    padding: 8px 24px;

    &>*+* {
      margin-top: 4px;
    }
  }

  p {
    margin: 0;
  }

}
