@use "../../abstracts" as abs;
@import '../../theme/palletes';

$widget-color-map: (
  caution: map-get($caution-palette, 300),
  danger: map-get($negative-palette, 300),
  info: map-get($info-palette, 300),
  success: map-get($success-palette, 300),
  primary-300: map-get($primary-palette, 300)
);

.widget {
  position: relative;

  &-inner.mat-mdc-card-content {
    padding: 24px;
  }

  mat-divider {
    margin: 8px 0;
  }

  &-title {
    font-weight: 600;
    margin-bottom: 8px;
    font-size: 16px;

    .widget-card & {
      font-size: 20px;
    }
  }

  &-header {
    display: flex;
    justify-content: space-between;
    min-height: 48px;
    margin-bottom: 8px;
  }

  &-header-text > * + * {
      margin-top: 8px;
  }

  &-text {
    font-size: 16px;
    line-height: 20px;
    opacity:.8;
  }

  &-text-sub {
    line-height: 1.5rem;
    font-size: 12px;
    opacity: 0.5;
    margin-bottom: 0.5rem;
  }

  &-content {
    .widget-info-card & {
      padding-right: calc(24px + 57px);
    }
  }

  &-icon-wrapper {
    box-shadow: 0 0 10px 5px var(--w-icon-shadow);
    background-color: var(--w-icon-bg, #fff);
    position: absolute;
    color: $light-primary;
    right: 24px;
    top: 50%;
    transform: translateY(-50%);
    width: 57px;
    height: 57px;
    border-radius: 2px;
    display: flex;
    align-items: center;
    justify-content: center;
  }


  &-chart-wrap {
    height: 400px;
    display: flex;
    flex-direction: column;

    .chart {
      flex: 1;
    }

    &>* {
      flex: 1;
    }
  }


  &-table-wrap {
    max-width: 100%;
    margin: 0 -16px;
    width: calc(100% + 32px);
    .table{
      width: 100%;
      overflow: auto;
    }
  }


}

@each $name,
$color in $widget-color-map {
  $color-shadow: rgba($color, 0.35);

  .widget-#{"" + $name} .widget-icon-wrapper {
    background-color: $color;
    box-shadow: 0 0 10px 5px $color-shadow;
  }
}


@include abs.respond-above(md) {
  .widget {
    .widget-info-card & {
      &-icon-wrapper {
        right: -10px;
      }

      &-content {
        padding-right: calc(-10px + 57px);
      }
    }
  }
}
