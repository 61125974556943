@import '../../theme/palletes';

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}


html,
body {
  width: 100%;
  position: relative;
}

.lyt {
  &-wrap {
    height: 100vh;
    overflow: hidden;

    .mat-drawer-container {
      background-color: $light-primary;
    }

    .mat-drawer-inner-container {
      scrollbar-gutter: stable;
      scrollbar-color: map-get($primary-palette, 100) map-get($primary-palette, 500);
    }
  }

  &-toolbar {
    background-color: $light-primary;
    box-shadow: 0 10px 30px #2f3c4a14;
    mat-toolbar{
      height: 70px;
    }
    &-spacer {
      flex-grow: 1;
    }
  }

  &-space-auto {
    margin: auto;
  }
}

.lyt-content {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  overflow-x: hidden;
  position: relative;
  min-height: 450px;

  &-inner {
    padding: 32px 30px;
  }

  &-wrap {
    width: 100%;
    display: flex;
    flex-direction: column;
    position: relative;
    float: right;
    height: 100vh;
  }
}

.lyt-container {
  display: flex;
  flex-direction: row;
  align-items: space-between;
  .lyt-sidebutton-position {
    position: absolute;
    z-index: 1;
    left: -15px;
    top: 75px;
    button {
      border-radius: 0 50% 50% 0;
      box-shadow: none;
      mat-icon {
        left: 3px;
      }
    }
  }
}
