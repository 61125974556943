.wrapper {
  position: relative;
  top: 0;
  height: 100vh;
}

.sidebar {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  z-index: 3 !important;
  width: 260px;

  img {
    position: absolute;
    z-index: 2000;
  }
  box-shadow: 10px 10px 10px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: 10px 10px 10px rgba(0, 0, 0, 0.3);
  -moz-box-shadow: 10px 10px 10px rgba(0, 0, 0, 0.3);
}

.sidebar-background {
  position: absolute;
  height: 100%;
  width: 100%;
  display: block;
  top: 0;
  left: 0;
  background-size: cover;
  background-position: center center;
  z-index: 0;
}

.header {
  margin-bottom: 30px;

  .title {
    margin-top: 10px;
    margin-bottom: 10px;
  }
}

.sidebar,
.main-panel,
.footer,
.sidebar-wrapper {
  -webkit-transition-property: top, bottom, width;
  transition-property: top, bottom, width;
  -webkit-transition-duration: .2s, .2s, .35s;
  transition-duration: .2s, .2s, .35s;
  -webkit-transition-timing-function: linear, linear, ease;
  transition-timing-function: linear, linear, ease;
  -webkit-overflow-scrolling: touch;
}

.sidebar-wrapper {

  ul {
    list-style-type: none;
  }

  a {
    text-decoration: none;
  }
}

@media (min-width: 900px) {

  .sidebar,
  .sidebar .sidebar-wrapper {
    width: 260px;
  }

  .sidebar {
    display: block;
    font-weight: 200;
  }

  .sidebar a {
    float: none;
    display: block;
  }

  .full-name {
    display: block;
    font-size: large;
  }

  .navigation-fms {
    margin-top: 5%;
  }
}

@media (max-width: 900px) {

  .sidebar,
  .sidebar .sidebar-wrapper {
    width: 160px;
  }

  .sidebar {
    img {
      display: none;
    }
  }

  .full-name {
    display: none;
  }

  .navigation-fms {
    margin-top: 20%;
  }
}

.navigation-fms {
  font-size: large;
  box-shadow: none;
  z-index: 100;
  position: fixed;
  margin-left: 5px;
}
