html.cdk-global-scrollblock {
  overflow: hidden;
}

.modal-dialog-pane {
  max-width: 80vw !important;
  width: 100%;
  max-height: 80vh;
}

.flex-space-between {
  display: flex !important;
  align-items: center;
  justify-content: space-between;
  overflow: inherit !important;
  flex-grow: 0 !important;
}

.modal-title-bold {
  font-size: 1.5em;
  font-weight: bold;
}

.page-details-title-style {
  text-align: center;
}