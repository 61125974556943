.vcc-viewer-form, .adx-vcc-viewer-form {
    .template-form {
        padding-left: 0 !important;
        padding-right: 0 !important;
    }

    .mat-mdc-tab-body-content {
        padding-top: 30px;
    }

    #vcc-payment-gateway-main-container {
        margin: 0;
    }

    .vcc-footer-update-container {
        text-align: left;
    }

    button {
        margin-bottom: 16px;
        &+ button {
            margin-left: 16px;
        }
    }
}

.adx-vcc-no-connexpay-alert{
    display: flex;
    justify-content: center;
    &__icon{
        line-height: 24px;
        margin-right: 8px;
        flex-shrink: 0;
    }
    
    &__label{
        line-height: 24px;
    }
}