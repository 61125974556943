@use "functions" as func;

// _responsive.scss

// Breakpoints Map
// ---------------
// Defines the various breakpoints for responsive design.
// The keys are the names of the breakpoints, and the values are the minimum widths.
$breakpoints: (
  'sm': func.rem(480),
  'md': func.rem(768),
  'lg': func.rem(1024),
  'xl': func.rem(1280)
);

// Respond Above Mixin
// -------------------
// This mixin generates a media query for styles that should apply at widths
// greater than the specified breakpoint.
//
// @param {String} $breakpoint-name - The name of the breakpoint (e.g., 'medium', 'large').
//
// @example SCSS Usage
//   .some-class {
//     @include respond-above('medium') {
//       font-size: 18px;
//     }
//   }
//
// @example Compiled CSS
//   @media (min-width: 768px) {
//     .some-class {
//       font-size: 18px;
//     }
//   }
//
@mixin respond-above($breakpoint-name) {
  $breakpoint: map-get($breakpoints, $breakpoint-name);

  // Check if the breakpoint exists in the map
  @if $breakpoint {
    @media (min-width: $breakpoint) {
      @content;
    }
  } @else {
    // Warn the user if the breakpoint is invalid
    @warn "Invalid breakpoint: #{$breakpoint-name}.";
  }
}
