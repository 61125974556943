* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}


html,
body {
  width: 100%;
  position: relative;
}

.lyt {
  &-wrap {
    height: 100vh;
    overflow: hidden;

    .mat-drawer-container {
      background-color: #fafafa;
    }
  }

  &-toolbar {
    background-color: #fff;
    box-shadow: 0 10px 30px #2f3c4a14;
    mat-toolbar{
      height: 70px;
    }
    &-spacer {
      flex-grow: 1;
    }
  }

  &-space-auto {
    margin: auto;
  }
}

.lyt-content {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  overflow-x: hidden;
  position: relative;
  min-height: 450px;

  &-inner {
    padding: 32px 24px;
  }

  &-wrap {
    width: 100%;
    display: flex;
    flex-direction: column;
    position: relative;
    float: right;
    height: 100vh;
  }
}
