
.table-borderless > tbody > tr > td,
.table-borderless > tbody > tr > th,
.table-borderless > tfoot > tr > td,
.table-borderless > tfoot > tr > th,
.table-borderless > thead > tr > td,
.table-borderless > thead > tr > th {
  border: none;
}


.vendorview-modal {
  padding: 10px 10px 10px 10px;
  overflow-x: hidden;
  overflow-y: auto;
}

 
.cdk-overlay-container {
  z-index: 9999;
}

.confirmpopup .title {
  float: center;
  font-size: large;
}

.spinner {
  left: 0%;
  top: -40%
}

