.rule-form {

  @media (min-width: 900px) {
    display: flex;

    .divider {
      min-width: 0.5% !important;
    }

    .amt {
      width: 15% !important;
    }

    .proramt {
      width: 15% !important;
    }

    .condition {
      width: 30% !important;
    }
  }

  .mat-mdc-form-field-error-wrapper {
    overflow: visible !important;
    z-index: 9999 !important;
  }
}

.template-form {
  @media (min-width: 900px) {
    padding-right: 100px;
    padding-bottom: 30px;
    padding-left: 100px;
  }
}

.template-form .title {
  @media (min-width: 900px) {
    width: 100%;
    text-align: center;
    font-size: large;
    padding-bottom:10px;
  }

  @media (max-width: 900px) {
    display: none;
  }
}

.template-input {
  width: 92% !important;
}

.view-form{
  min-width: 600px;
}
