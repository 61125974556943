.vendorTemplateActions {
  display: inline-grid;
}

::ng-deep .mat-grid-tile-content {
  justify-content: left !important;
}

.mat-mdc-form-field {
  width: 100% !important;
}

.card-container {
  padding: 10px;
  width: inherit;
  display: flex;
  justify-content: space-between;
  border: 1px solid;
  border-radius: 3px;
}

textarea {
  resize: none;
}

