
.modal-panel,
.modal-panel-wrapper {
  display: block;
  height: 100%;
  width: 100%;
}

.modal-panel {
  display: flex;
  flex-direction: column;

  &__header,
  &__footer {
    flex: 0 0 auto;
  }

  &__header {
    height: 60px;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;
    padding: 12px;
  }

  &__title {
    margin: 0;
    flex: 1;
    &.mat-headline-6{
      margin: 0;
    }
  }

  &__close {
    &.mat-mdc-icon-button .mat-mdc-button-persistent-ripple {
      opacity: 0.3;
      border-radius: unset;
    }
  }

  &__body {
    position: relative;
    flex: 1;
    height: 100%;

    &-inner {
      max-height: 56vh;
      overflow-x: hidden;
      overflow-y: auto;
      padding: 24px 16px;
    }
  }

  &__footer {
    padding: 8px 12px 12px;
  }
}

.filter-panel {
  max-height: 75vh !important;
  width: 36vw;
  @media (width <= 920px) {
    width: 60vw;
  }
  .modal-footer {
    display: flex;
    justify-content: space-between;
  }
}

.word-break-text {
  word-break: break-all;
}