@use '@angular/material' as mat;
@import '~@angular/material/prebuilt-themes/indigo-pink.css';
@import 'core/sidebar-fms';
@import 'core/vendor-fms';
@import 'core/vendor-template-fms';
@import 'core/template-fms';
@import 'components';
@import 'layout';
@import 'utilities';
@import 'theme';
@import './theme/palletes';

body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}


@media (max-width: 900px) {
  .center {
    display: flex;
    justify-content: right;
  }

  .fixed-bottom {
    display: none;
  }

  .main-panel {
    padding-left: 180px;
  }
}

@media (min-width: 900px) {
  .center {
    display: flex;
    justify-content: center;
  }

  .main-panel {
    padding-left: 280px;
  }
}
  .main-panel {
    padding-top: 5%;
    padding-right: 20px;
    height: 80%;
  }

  .mat-mdc-menu-content {
    padding: 8px 8px 8px 8px !important;
  }

  .global-spinner {
    position: fixed !important;
    top: 50% !important;
    left: 50% !important;
    transform: translate(-50%, -50%) !important;
    z-index: 999999 !important;
  }

  .loading-spinner {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1.8rem 0;
  }

  .navbar-spacer {
    flex: 1 1 auto;
  }

  .fixed-header {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 2 !important;
    width: 100% !important;
    padding-left: 200px;
  }

  .close-button {
    margin-top: 5px;
  }

  .items-space-between {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .items-space-around {
    display: flex;
    justify-content: space-around;
    align-items: center;
  }

  .flex-end {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }

  .justify-center {
    display: flex;
    justify-content: center;
  }

  .chartCard {
    width: 84.5vw;
    height: calc(78vh - 40px);
    background: rgba(54, 162, 235, 0.2);
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .chartBox {
    width: 700px;
    padding: 20px;
    border-radius: 20px;
    border: solid 3px rgba(54, 162, 235, 1);
    background: white;
  }

  #summaryCharts > .chartBox > .chart {
    height: calc(55vh);
  }

  table {
    width: 100%;
  }

  .mat-column-transactions {
    white-space: unset !important;
    flex: 0 0 50% !important;
    width: 50% !important;
    overflow-wrap: break-word;
    word-wrap: break-word;
    word-break: break-word;
    -ms-hyphens: auto;
    -moz-hyphens: auto;
    -webkit-hyphens: auto;
    hyphens: auto;
  }

  .mat-column-userUpdated {
    white-space: unset !important;
    flex: 0 0 25% !important;
    width: 25% !important;
    overflow-wrap: break-word;
    word-wrap: break-word;
    word-break: break-word;
    -ms-hyphens: auto;
    -moz-hyphens: auto;
    -webkit-hyphens: auto;
    hyphens: auto;
  }

  .wrapper {
    overflow: hidden;
  }

  #no-access-mainbox-div a:hover {
    cursor: pointer;
  }

  .mat-mdc-snack-bar-label {
    white-space: pre-line;
    max-height: 50vh;
  }

  .mdc-snackbar__surface {
    overflow: auto;
  }

  .mat-button-clear-search-filter {
    min-height: 56px;
    padding-left: 27px !important;
    margin-bottom: 22px;
  }

  .vcc-daily-summary-report-filter-modal-panel-cls .modal-panel__body-inner,
  .fms-vcc-pay-modal-panel-cls .modal-panel__body-inner,
  .vcc-chart-filter-modal-panel-cls .modal-panel__body-inner {
    max-height: 75vh !important;
    width: 36vw;
    overflow-y: hidden !important;
  }

  div.grid-title-event,
  section.grid-title-event {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1vh;

    @media (width < 520px) {
      flex-direction: column;
    }

    div.events-container {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 12px;

      .btn-download {
        display: flex;
        justify-content: end;
      }
    }
  }

  .m-t-8 {
    margin-top: 8%;
  }

  .m-b-5 {
    margin-bottom: 5%;
  }

  .p-l-2 {
    padding-left: 2.5% !important;
  }

  .overflowAuto {
    overflow: auto
  }

  .flex-center {
    display: flex;
    align-items: center;
  }

  .chatbox.open {
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;

    .chatbox-conversation {
      flex: 1;
    }
  }

  .conversation-container {
    max-height: 58vh;
    overflow-y: auto;

    @media (width < 520px) {
      max-height: 80vh;
    }
  }

  .message-container {
    display: flex;
    align-items: flex-start;
    margin: 10px 0;
  }

  .avatar {
    width: 40px;
    height: 40px;
    margin-right: 10px;
  }

  .avatar img {
    width: 100%;
    border-radius: 50%;
  }

  .message {
    background-color: map-get($neutral-palette, 100);
    border-radius: 10px;
    padding: 10px;
    max-width: 70%;
    overflow-wrap: break-word;
  }

  .message p {
    margin: 0;
  }

  .user-message {
    align-items: flex-start;
    flex-direction: row-reverse;

    .message {
      background-color: map-get($primary-palette, 500);
      color: $light-primary;
    }
  }

  .chat-prompt-input {
    display: flex;
    align-items: center;
    padding: 10px 0 10px 0;
    border-top: 1px solid map-get($neutral-palette, 300);
  }

  .chat-prompt-input input {
    flex: 1;
    padding: 8px;
    border: none;
    border-radius: 20px;
    outline: none;
  }

  .chat-prompt-input button {
    --icon-size: 14px;
    position: relative;
    display: flex;
    justify-content: flex-end;
    width: 46px;
    background-color: map-get($primary-palette, 500);
    color: $light-primary;
    border: none;
    border-radius: 20px;
    padding: 8px 16px;
    margin-left: 10px;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
  }

  .chat-prompt-input button:hover {
    width: 83px;
  }

  .chat-prompt-input button:hover span {
    opacity: 1;
    right: 15px;
    width: calc(100% - var(--icon-size));
  }

  .chat-prompt-input button span {
    position: absolute;
    right: 0;
    width: 0%;
    opacity: 0;
    transition: all 0.3s ease-in-out;
  }

  .chat-prompt-input button mat-icon {
    width: var(--icon-size);
    height: var(--icon-size);
    font-size: var(--icon-size);
  }

  .chatbot-icon {
    position: fixed;
    bottom: 20px;
    right: 20px;
    z-index: 1000;
    cursor: pointer;
    color: map-get($neutral-palette, 500);
  }

  .chatbox {
    --chat-container-border-radius: 15px;
    --chat-container-padding: 10px;
    position: fixed;
    bottom: 80px; /* Adjust this value according to your layout */
    right: 20px;
    width: 50vw;
    height: 75vh;
    background-color: map-get($neutral-palette, 200);
    border-radius: var(--chat-container-border-radius);
    box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.35);
    display: none;

    @media (width < 520px) {
      width: 100%;
      height: 100%;
      right: 0;
      bottom: 0;
    }

    .header {
      display: flex;
      justify-content: flex-end;
      margin: 0;
    }

    .footer {
      padding: 0 var(--chat-container-padding) var(--chat-container-padding) var(--chat-container-padding);
      border-bottom-left-radius: var(--chat-container-border-radius);
      border-bottom-right-radius: var(--chat-container-border-radius);
      background-color: $light-primary;
    }
  }

  .chatbox.open {
    display: flex;
    z-index: 1;
    flex-direction: column;
    flex: 1 1 auto;

    @media (width < 520px) {
      z-index: 1001;
    }

    .chatbox-conversation {
      flex: 1;
      padding: 0 var(--chat-container-padding);
      border-top-left-radius: var(--chat-container-border-radius);
      border-top-right-radius: var(--chat-container-border-radius);
      background-color: $light-primary;
    }
  }

  .li-style-initial {
    list-style-type: initial;
  }

  .w-100 {
    width: 100%;
  }

  .w-25-vw {
    width: 25vw;
  }

  read-only-form-field {
    width: 100%;
    color: $dark-primary;
  }

  .mat-column-action button {
    margin: 2px;
  }

  .mat-column-action {
    width: 20%;
    white-space: unset !important;
    flex: 0 0 15% !important;
    width: 15% !important;
    overflow-wrap: break-word;
    word-wrap: break-word;
    word-break: break-word;
    -ms-hyphens: auto;
    -moz-hyphens: auto;
    -webkit-hyphens: auto;
    hyphens: auto;
    text-align: center;
  }

  .preserve-whitespace {
    white-space: pre;
  }

  .mat-table {
    overflow-x: scroll;
  }

.mat-cell,
.mat-header-cell {
  word-wrap: initial;
  padding: 0px 10px;
  line-break: unset;
  width: 100%;
  overflow: hidden;
  vertical-align: middle;
  text-overflow: ellipsis;
}

  .mat-row,
  .mat-header-row {
    display: table-row;
  }


@media screen and (max-width: 1800px){
  .hideOnMin {
    display: none;
  }
}

