form{
    .two-col-grid {
        display: grid;
        grid-template-columns: 40% 55%;
        grid-template-rows: 1fr;
        row-gap: 10px;
        column-gap: 20px;
    }

    @media screen and (max-width: 992px){
        .two-col-grid{
           grid-template-columns: repeat(2, 1fr);
        }
     }
}

input[type=password]::-ms-reveal, input[type=password]::-ms-clear {
    display: none;
}