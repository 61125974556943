@use "../abstracts" as abs;

// Row
.row {
  @include abs.make-row();

  >* {
    @include abs.make-col-ready();
  }
}

// Columns
//
// Common styles for small and large grid columns

@include abs.make-grid-columns();
