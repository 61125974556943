mat-table.compact-mat-table {
    mat-cell > span.compact-mat-cell-text {
        text-overflow: ellipsis; 
        overflow: hidden; 
        white-space: nowrap;
    }

    span.align-text-left {
        text-align: left;
    }

    .flexible-content {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        width: 100%;
    }

    .mat-column-extra-width {
        flex: 0 0 15%;
    }

    span.mat-column-wrap {
        width: 100%;
    }
}